<template>
  <t-button :variant="variant" @click="$emit('click')" :style="getStyle()">
    <slot>
      <svg
        class="w-6 h-5"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
        ></path>
      </svg>
      <template v-if="text">
        <span class="ml-2">{{ text }}</span>
      </template>
    </slot>
  </t-button>
</template>

<script>
export default {
  props: ['text', 'variant'],
  methods: {
    getStyle() {
      return this.text ? '' : 'padding-left: 6px; padding-right: 6px'
    },
    getClass() {
      return this.text ? '' : 'w-36'
    },
  },
}
</script>

<style></style>
