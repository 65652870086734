<template>
  <div>
    <h3 class="pb-4 text-xl font-bold uppercase text-oDark">
      Tags
    </h3>
    <OtoTag
      :entity-id="id"
      :entity-type="'bike'"
      :editPermissionStatus="$acl.canNotUpdate('vehicles')"
      :deletePermissionStatus="$acl.canNotDelete('vehicles')"
    />
  </div>
</template>

<script>
import OtoTag from '@/composites/tag/OtoTag.vue'
export default {
  props: ['id'],
  components: {
    OtoTag,
  },
  setup() {},
}
</script>
